<template>
  <div class="inBox">
    <div class="inBoxLeft">
      <div class="leftTop">
        <p @click="gomore(1)">查看更多>></p>
        <ul>
          <li @click="tabClick(0)" :class="controlDiv === 0 ? 'liStyle' : '' ">
            <span @click="changeTime($event)">
              {{weekDate[0]}}<br/>
              周一
            </span>
          </li>
          <li @click="tabClick(1)" :class="controlDiv === 1 ? 'liStyle' : '' ">
            <span @click="changeTime($event)">
              {{weekDate[1]}}<br/>
              周二
            </span>
          </li>
          <li @click="tabClick(2)" :class="controlDiv === 2 ? 'liStyle' : '' ">
            <span @click="changeTime($event)">
              {{weekDate[2]}}<br/>
              周三
            </span>
          </li>
          <li @click="tabClick(3)" :class="controlDiv === 3 ? 'liStyle' : '' ">
            <span @click="changeTime($event)">
              {{weekDate[3]}}<br/>
              周四
            </span>
          </li>
          <li @click="tabClick(4)" :class="controlDiv === 4 ? 'liStyle' : '' ">
            <span @click="changeTime($event)">
              {{weekDate[4]}}<br/>
              周五
            </span>
          </li>
          <li @click="tabClick(5)" :class="controlDiv === 5 ? 'liStyle' : '' ">
            <span @click="changeTime($event)">
              {{weekDate[5]}}<br/>
              周六
            </span> 
          </li>
          <li @click="tabClick(6)" :class="controlDiv === 6 ? 'liStyle' : '' ">
            <span @click="changeTime($event)">
              {{weekDate[6]}}<br/>
              周天
            </span>
          </li>
        </ul>
      </div>
      <div class="leftBottom">
        <el-table
          :data="tableData"
          style="width: 100%"
          v-loading="loading"
          :header-cell-style="{textAlign: 'center',color:'#999999',height:'65px'}"
          :row-style="{height:'65px'}"
          :cell-style="{ textAlign: 'center',color:'#959595'}">
          <el-table-column
            prop="Time"
            label="时间">
          </el-table-column>
          <el-table-column
            prop="CalendarName"
            label="事件名称"
            :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column
            prop="Importance"
            label="重要性">
            <template slot-scope="scope">
              <div v-if="scope.row.Importance == 1">
                <i style="color:#ffad39" class="iconfont icon-shoucang"></i>
                <i class="iconfont icon-shoucang"></i>
                <i class="iconfont icon-shoucang"></i>
                <i class="iconfont icon-shoucang"></i>
                <i class="iconfont icon-shoucang"></i>
              </div>
              <div v-if="scope.row.Importance == 2">
                <i style="color:#ffad39" class="iconfont icon-shoucang"></i>
                <i style="color:#ffad39" class="iconfont icon-shoucang"></i>
                <i class="iconfont icon-shoucang"></i>
                <i class="iconfont icon-shoucang"></i>
                <i class="iconfont icon-shoucang"></i>
              </div>
              <div v-if="scope.row.Importance == 3">
                <i style="color:red" class="iconfont icon-shoucang"></i>
                <i style="color:red" class="iconfont icon-shoucang"></i>
                <i style="color:red" class="iconfont icon-shoucang"></i>
                <i class="iconfont icon-shoucang"></i>
                <i class="iconfont icon-shoucang"></i>
              </div>
              <div v-if="scope.row.Importance == 4">
                <i style="color:red" class="iconfont icon-shoucang"></i>
                <i style="color:red" class="iconfont icon-shoucang"></i>
                <i style="color:red" class="iconfont icon-shoucang"></i>
                <i style="color:red" class="iconfont icon-shoucang"></i>
                <i class="iconfont icon-shoucang"></i>
              </div>
              <div v-if="scope.row.Importance == 5">
                <i style="color:red" class="iconfont icon-shoucang"></i>
                <i style="color:red" class="iconfont icon-shoucang"></i>
                <i style="color:red" class="iconfont icon-shoucang"></i>
                <i style="color:red" class="iconfont icon-shoucang"></i>
                <i style="color:red" class="iconfont icon-shoucang"></i>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="Before_value"
            label="前值">
          </el-table-column>
          <el-table-column
            prop="PublicValue"
            label="预测值">
          </el-table-column>
          <el-table-column
            prop="Influence"
            label="公布值">
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div class="inBoxRight">
      <div class="rightTop">
        <p @click="gomore(2)">查看更多>></p>
        <ul></ul>
      </div>
      <div class="rightBottom">
        <ul>
          <li v-for="(item,i) in comment" :key="i">
            <p>
              <span></span>
              <span class="craigTxt">{{item.Title}}</span>
            </p>
            <span class="liDate">{{item.NewsTime}}</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { number } from 'echarts';
import {getHeadlineNewsApi,getViewListWithHolidayApi,newList} from '../../../api/info';
export default {
  name:'information',
  data() {
    return {
      // 控制leftTop
      controlDiv:1,
      // 专家评论数据
      comment:[],
      // 表格数据
      tableData:[],
      // 该日所在周的所有日期
      weekDate:[],
      loading: true,
    }
  },
  created(){
    // const apiParams = {
    //   'businessPlatform': 8,
    //   'terminal': 4,
    //   'headlineNewsType': 1,
    //   'maxResultCount': 9,
    //   'enablePaging': true,
    //   'skipCount': 0
    // };
    const apiParams={
      'pageIndex':1,
      'pageRow':9
    };
    // 获取新闻头条
    newList(apiParams).then(res=>{
      // if(res.success == true){
      //   this.comment = res.data;
      //   console.log(this.comment,111)
      // }
      if(res.ok){
            this.comment = res.data;
      console.log(this.comment,111)
      }
    });
    // 初始化获取当前时间的财经日历
    this.initViewList((new Date()).Format('yyyyMMdd'));
    // 初始化当前日期对应的一整周日期
    this.getWeek((new Date()).Format('yyyy-MM-dd'));
    // 控制显示哪个日期
    this.controlDiv = Number(this.craigJudge((new Date()).Format('yyyy-MM-dd')));
  },
  methods:{
    // 获取财经日历方法
    initViewList(attr){
      const timer = {
        date: attr,
        type: 0,
      };
      getViewListWithHolidayApi(timer).then(res=>{
        if(res.Code == 0){
          this.loading = false;
          this.tableData = res.Result.splice(0,7);
        }
      })
    },
    // tabbar点击事件
    tabClick(attr){
      this.controlDiv = attr;
    },
    // 点击切换日期
    changeTime(event){
      this.loading = true;
      // 截取点击后的具体时间
      let attr = (event.target.innerHTML.split('<')[0].replace(/\s*/g,"")).replace(/-/g,"");
      this.initViewList(attr);
    },
    // 根据某一天日期，获取该日所在周的所有日期
    getWeek(input_day){
      // 一天里一共的毫秒数
      let oneDayTime = 1000 * 60 * 60 * 24 
      let today = new Date(input_day)
      // 若那一天是周末时，则强制赋值为7
      let todayDay = today.getDay() || 7 
      let startDate = new Date(
        today.getTime() - oneDayTime * (todayDay - 1)
      )
      for(let i = 0; i < 7; i++){
        let temp = new Date(startDate.getTime() + i * oneDayTime)
        let year = temp.getFullYear();
        let month = temp.getMonth() + 1;
        if(month<10){
          month = '0'+month;
        }
        let day = temp.getDate();
        if(day<10){
          day = '0'+day;
        }
        this.weekDate[i] = `${year}-${month}-${day}` 
      }
      return this.weekDate;
    },
    // 根据日期判断与weekdata的第几个元素相等
    craigJudge(key){
      for(let i in this.weekDate){
        if(key == this.weekDate[i]){
          return i;
        }
      }
    },
    // 点击查看更多
    gomore(attr){
      if(attr == 1){
        this.$router.push({
          name:'Market',
        });
      }else{
        this.$router.push({
          name:'Market',
          query:{
            key:4,
          }
        });
      }
    }
  }
}
</script>

<style lang="less" scoped>
  .inBox{
    max-width: 1416px;
    width: 100%;
    max-width: 1416px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 2fr 1fr;
    column-gap: 20px;
    // justify-content: space-between;
    // align-items: center;
    .inBoxLeft{
      max-width: 936px;
      height: auto;
      .leftTop{
        width: 100%;
        height: 190px;
        background: url('../../../static/index/information1.png') no-repeat;
        background-size: cover;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;
        border-top-right-radius: 15px;
        p{
          color: #fffffd;
          margin-top: 10px;
          margin-right: 30px;
          cursor: pointer;
        }
        ul{
          width: 100%;
          display: grid;
          grid-template-columns: repeat(7,1fr);
          // justify-content: center;
          align-items: center;
          box-sizing: border-box;
          li{
            max-width: 125px;
            height: 80px;
            color: #fffffd;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            cursor: pointer;
            span{
              width: 100%;
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              text-align: center;
            }
          }
          .liStyle{
            background-color: #fffffd;
            color: #333333;
            border-top-right-radius: 15px;
            border-top-left-radius: 15px;
          }
        }
      }
      .leftBottom{
        width: 100%;
        height: 545px;
        background-color: #ffffff;
        border-bottom-right-radius: 15px;
        border-bottom-left-radius: 15px;
      }
    }
    .inBoxRight{
      max-width: 456px;
      height: auto;
      .rightTop{
        width: 100%;
        height: 116px;
        background: url('../../../static/index/information2.png') no-repeat;
        background-size: cover;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;
        p{
          color: #fffffd;
          margin-top: 10px;
          margin-right: 30px;
          cursor: pointer;
        }
      }
      .rightBottom{
        width: 100%;
        height: 619px;
        background-color: #ffffff;
        border-bottom-right-radius: 15px;
        border-bottom-left-radius: 15px;
        box-sizing: border-box;
        padding-top: 20px;
        ul{
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          li{
            width: 90%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            box-sizing: border-box;
            padding: 10px 0;
            list-style-type: disc;
            border-bottom: 1px solid #eaeaea;
            color: #666666;
            .liDate{
              width: 105px;
              color: #9a9a9a;
              font-size: 13px;
            }
            p{
              height: 40px;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              .craigTxt{
                width: 230px;
                font-size: 16px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow:ellipsis;
              }
              span:nth-child(1){
                display: block;
                width: 4px;
                height: 4px;
                margin-right: 15px;
                border-radius: 50%;
                background-color: #666666;
              }
            }
          }
        }
      }
    }
  }
  /deep/.icon-shoucang{
    color: #dddddd;
    margin-right: 2px;
  }
  @media screen and (max-width: 500px) {
    .inBox{
      width: 97%;
      flex-direction: column;
      display: block;
      .inBoxLeft{
        width: 100%;
        margin-bottom: 30px;
        .leftTop{
          height: 110px;
          p{
            font-size: 14px;
            margin-right: 10px;
          }
          ul{
            display: block;
            white-space: nowrap;
            overflow-x: auto;
            li{
              display: inline-block;
              width: 20%;
              height: 60px;
              span{
                font-size: 13px;
              }
            }
          }
          ul::-webkit-scrollbar {
            display: none;
          }
        }
        .leftBottom{
          height: auto;
          box-sizing: border-box;
          padding-bottom: 10px;
          /deep/.el-table{
            .el-table__row{
              height: 55px !important;
            }
            .cell{
              white-space: nowrap;
            }
            .el-table__header{
              height: 55px;
            }
          }
        }
      }
      .inBoxRight{
        width: 100%;
        .rightTop{
          height: 100px;
          p{
            font-size: 14px;
            margin-right: 10px;
          }
        }
        .rightBottom{
          height: auto;
          box-sizing: border-box;
          padding-bottom: 10px;
          ul{
            li{
              p{
                height: 30px;
                .craigTxt{
                  font-size: 14px;
                }
              }
              .liDate{
                font-size: 12px;
              }
            }
          }
        }
      }
    }
  }
</style>